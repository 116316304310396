<template>
    <div class="bg-white boxed p-md-4 newsfeed-card radius20 cardBorder">
        <div class="form-froup position-relative mb-4">
            <input type="search" class="form-control min-44" placeholder="Search Hashtags" v-model="searchHastag" @input="searchHastagList()">
            <vue-feather type="search" class="searchIcon"></vue-feather>
        </div>
        <ul class="trendign-list" v-if="store.socialFeedSearchHastagName && store.socialFeedSearchHastagName.length">
            <li v-for="hastag,key in store.socialFeedSearchHastagName" :key="key">
                <a class="neutralGrey" href="javascript:void(0)" @click="socialHastagList(hastag.name)">#{{hastag.name}}</a>
                <a class="neutralGrey" :class="[{'button fillBtn zulu_btn small':!hastag.isFollowed},{'disabled':store.customerDetail?.readOnly},{'disable-bg':hastag.isFollowed && store.customerDetail?.readOnly}]" href="javascript:void(0)" @click="followHastag(hastag.id,hastag.isFollowed)">{{hastag.isFollowed? $t('leader_profile.followed') : $t('leader_profile.follow') }}</a>
            </li>
        </ul>
        <div v-else>
            <h1 class="headings letter-space-0 f-16 bold line-1">{{$t('leader_profile.trending')}}</h1>
            <ul class="trendign-list" v-if="store.socialHastagRightList && store.socialHastagRightList.length">
                <li v-for="hastag,key in store.socialHastagRightList" :key="key">
                    <a class="neutralGrey" href="javascript:void(0)" @click="socialHastagList(hastag.name)">{{key+1}}. #{{hastag.name}}</a>
                    <a class="neutralGrey"  :class="[{'button fillBtn zulu_btn small':!hastag.isFollowed},{'disabled':store.customerDetail?.readOnly},{'disable-bg':hastag.isFollowed && store.customerDetail?.readOnly}]"  href="javascript:void(0)" @click="followHastag(hastag.id,hastag.isFollowed)">{{hastag.isFollowed? $t('leader_profile.followed') : $t('leader_profile.follow') }}</a>
                </li>
            </ul>
        </div>
        <Nodata v-if="!store.socialFeedSearchHastagName.length && !store.socialHastagRightList.length"/>
    </div>
    <div class="myHastag mb-3" v-if="store.user.access_token && store.socialFeedPageMyHastags && store.socialFeedPageMyHastags.length">
        <h6 class="headings f-18">{{$t('leader_profile.myHashtags')}}</h6>
        <ul class="d-flex align-items-center flex-wrap">
            <li v-for="item,key in store.socialFeedPageMyHastags.slice(0,myHastagsSlice)" :key="key" class="neutralGrey d-inline-flex align-items-center bg-white pointer"><span @click="socialHastagList(item.name)">#{{item.name}}</span><a class="d-flex" :class="{'disabled disable-bg' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather :title="$t('leader_profile.text8')" @click="followHastag(item.id,isFollowed=true)" class="ms-1" size="16" type="x"></vue-feather> </a></li>

            <li class="neutralGrey d-inline-flex align-items-center" v-if="store.socialFeedPageMyHastags.length > 10">{{showViewAllHastags?'':'+'}}{{showViewAllHastags?'':store.socialFeedPageMyHastags.length - 10}} &nbsp; <a class="secondary" href="javascript:void(0)" @click="showViewAllHastags?myHastagsSlice=10:myHastagsSlice=1000;showViewAllHastags=!showViewAllHastags">{{showViewAllHastags? $t('leader_profile.viewLess') : $t('leader_profile.viewAll') }} </a></li>
        </ul>
    </div>
    <loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        emits: ['getSocialFeedHastag'],
        data(){
            return {
                searchHastag: '',
                hasFollow: '',
                myHastagsSlice: 10,
                showViewAllHastags: false,
                showLoginPopup: false,
            }
        },
        methods: {
            searchHastagList(){
                if(this.searchHastag.length > 1){
                    this.store.callSocialFeedSearchHastagName({},false,this.searchHastag)
                } else {
                    this.store.$patch({socialFeedSearchHastagName : []})
                }
            },
            socialHastagList(hastagName){
                if(this.$route.name=='socialFeed'){
                    this.$emit('getSocialFeedHastag',hastagName)
                    this.store.callSocialHastagList({},false,hastagName)
                }
            },
            followHastag(id,follow){
                if(!this.store.user.access_token){
                    this.showLoginPopup = true
                } else {
                    if(follow){
                        this.hasFollow = 'followed'
                        this.store.postSocialFeedFollow({},false,id,this.hasFollow).then(()=>{
                            this.store.getSocialFeedMyHastags({},false)
                            this.store.callSocialFeedPageList({},true)
                        })
                    } else {
                        this.hasFollow = 'follow'
                        this.store.postSocialFeedFollow({},false,id,this.hasFollow).then(()=>{
                            this.store.getSocialFeedMyHastags({},false)
                            this.store.callSocialFeedPageList({},true)
                        })
                    }
                }
            },
        },
        created() {
            this.store.callSocialFeedPageHastagRightList({},false)
            this.store.$patch({socialFeedSearchHastagName : []})
            if(this.store.user.access_token){
                this.store.getSocialFeedMyHastags({},false)
            }
        },
   
    };
</script>