 <template>
    <section class="blockElement vuewFullSocialFeed pb-3 pt-md-5 mt-3" :class="[{'withoutExpand':!store.user.access_token}]">
        <loginPopup v-if="showLoginPopup"></loginPopup>
        <div class="container">
            <div class="row">
                <!-- <div class="col-12 col-xl-3">
                    <h2 class="f-28 bold d-flex align-items-center mb-4"><img class="me-2" src="/assets/images/small-feed.png" :alt="$t('leader_profile.text7')" /> {{$t('leader_profile.feed')}}</h2>
                    <div class="verticalTabs calcLeftHeight">
                        <ul>
                            <li>
                                <a href="javascript:void(0);" class="d-flex p-3 rounded align-items-center" :class="[{'active':feedType=='1'}]" @click="feedType=1;feedList()">
                                    <span class="me-3 d-flex"> <span class="maskedIcon"></span> </span>
                                    <span class="mb-0 f-18 medium">{{$t('leader_profile.community')}}</span>
                                </a>
                            </li>
                            <li class="mt-1" v-if="store.user.access_token">
                                <a href="javascript:void(0);" class="d-flex p-3 rounded align-items-center" :class="[{'active':feedType=='2'}]" @click="feedType=2;feedList()">
                                    <span class="me-3 d-flex">
                                        <span class="maskedIcon myPostp"></span>
                                    </span>
                                    <span class="mb-0 f-18 medium">{{(Object.keys(store.userDetail).length && store.userDetail.firstName)? store.userDetail.firstName : $t('leader_profile.myText')}} {{$t('leader_profile.posts')}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <div class="col-12 col-xl-8">
                    <div class="verticalTabs mw-100">
                        <ul class="d-flex align-items-center">
                            <li class="me-1" v-if="store.user.access_token">
                                <a href="javascript:void(0);" class="d-flex flex-nowrap p-3 rounded align-items-center" :class="[{'active':feedType=='1'}]" @click="feedType=1;feedList()">
                                    <span class="me-2 d-flex"> <span class="maskedIcon stIcon2"></span> </span>
                                    <span class="mb-0 f-16 medium">{{$t('leader_profile.postsFollowing')}}</span>
                                </a>
                            </li>
                            <li class="me-1">
                                <a href="javascript:void(0);" class="d-flex flex-nowrap p-3 rounded align-items-center" :class="[{'active':feedType=='3' || !store.user.access_token}]" @click="feedType=3;feedList()">
                                    <span class="me-2 d-flex"> <span class="maskedIcon stIcon1"></span> </span>
                                    <span class="mb-0 f-16 medium">{{$t('leader_profile.postsTrending')}}</span>
                                </a>
                            </li>
                            <li class="me-1" v-if="store.user.access_token">
                                <a href="javascript:void(0);" class="d-flex flex-nowrap p-3 rounded align-items-center" :class="[{'active':feedType=='2'}]" @click="feedType=2;feedList()">
                                    <span class="me-2 d-flex">
                                        <span class="maskedIcon stIcon3"></span>
                                    </span>
                                    <span class="mb-0 f-16 medium">{{$t('leader_profile.posts')}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <Social :rightHastag="rightHastag" :feedType="feedType" :clearRightHastag="()=>rightHastag=''"></Social>
                    <Nodata v-if="!(Object.keys(store.socialList).length && store.socialList.content && store.socialList.content.length > 0)"></Nodata>
                </div>
                <div class="col-12 col-xl-4">
                    <div class="position-sticky rightBarFixed">
                        <Sidebar @getSocialFeedHastag="getSocialFeedHastag"></Sidebar>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import Social from '@/views/trader/social/social.vue'
    import Sidebar from '@/views/social-feed/sidebar.vue'
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data(){
            return {
                showLoginPopup: false,
                feedType: 1,
                rightHastag: '',
            }
        },
        components: {
            Social, Sidebar
        },
        methods: {
            getSocialFeedHastag(value){
                this.rightHastag = value
            },
            feedList(){
                if(this.feedType=='1') { this.store.callSocialFeedPageList({},true) }
                else if(this.feedType=='3') { this.store.callSocialFeedPageList({},true,'noAuth') }
                else { this.store.callmyFeedPageList({},true) }
            },
        },
        created() {
            this.feedList()
        },
   
    };
</script>